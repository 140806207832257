<template>
  <main
    @dragenter.prevent="toggleActive"
    @dragover.prevent
    @dragleave.prevent="toggleActive"
    @drop.prevent="stageFilesByDrag"
    :class="[{ active: active }]"
    class="dropzone"
  >
    <section class="container">
      <div v-show="!files.length" class="preview-info">
        <i class="i-upload-pic"></i>
        <label for="dropzoneInput" class="-xstb">{{ title }}</label>
      </div>
      <div class="preview-container mt-4" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div class="preview-content">
            <div class="bar-loading">
              <img class="preview-img" :src="generateThumbnail(file)" />
              <div class="separator">
                <p :title="file.name">
                  {{ makeName(file.name) }}
                </p>
                <div v-show="isLoading" class="progress">
                  <div class="color"></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <b>&times;</b>
            </button>
          </div>
        </div>
      </div>

      <input
        type="file"
        id="dropzoneInput"
        ref="dropzoneInput"
        class="dropzoneInput"
        accept="image/*"
        @change="stageFilesByInput"
      />
      <label for="dropzoneInput" class="-xstb browse-btn">Browse </label>
    </section>
  </main>
</template>

<script>
export default {
  name: "DropZone",
  //type can be SINGLE or MULTIPLE
  props: ["type", "title"],
  emits: ["commitFiles"],

  data() {
    return {
      isLoading: false,
      active: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
    },
    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    makeName(name) {
      return (
        name.split(".")[0].substring(0, 10) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    stageFilesByDrag(e) {
      this.toggleActive();
      switch (this.type) {
        case "single":
          this.files[0] = e.dataTransfer.files[0];

          break;
        case "multiple":
          this.files.push(e.dataTransfer.files[0]);

          break;
        default:
          alert("Error uploading logo");
          break;
      }
    },
    stageFilesByInput() {
      if (this.$refs.dropzoneInput.files.length) {
        switch (this.type) {
          case "single":
            console.log(this.$refs.dropzoneInput.files[0].type);

            this.files[0] = this.$refs.dropzoneInput.files[0];
            break;
          case "multiple":
            this.files.push(this.$refs.dropzoneInput.files[0]);
            break;
          default:
            alert("Error uploading logo");
            break;
        }
      }
    },
    removeFile(toRemove) {
      this.files = this.files.filter((file) => file !== toRemove);
    },
    toggleActive() {
      this.active = !this.active;
    },
  },
  watch: {
    files: {
      handler: function () {
        this.$emit("commitFiles", this.files);
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 2500);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.dropzone {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 400px;
  padding-block: 8px;
  padding-inline: 8px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  /* background: var(--gray6); */
  border: 2px dashed var(--secondary);
  border-radius: 16px;
  transition: all 200ms ease;
  overflow: auto;
}
.container {
  display: flex;
  height: calc(100% - 20px);
  width: 100%;
  padding-top: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.browse-btn {
  width: 106px;
  padding: 8px;
  border-radius: 4px;
  background: var(--secondary);
  transition: 200ms ease;
  cursor: pointer;
  text-align: center !important;
}
.ml-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 1rem;
  font-weight: 100;
  color: white;
  background-color: var(--secondary);
  border: none;
  line-height: 0%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.5s;
}
.ml-2:hover {
  transform: scale(1.25);
  background-color: var(--highlight);
}
.i-upload-pic {
  transform: scale(1.25);
  padding-left: 4px;
}
input {
  display: none;
}

.bar-loading {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.preview-info {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.preview-container {
  display: flex;
  flex-flow: column;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.preview-card {
  display: flex;
  justify-content: space-between;
  width: 300px;
  border: 2px solid var(--secondary);
  padding: 5px;
  margin-left: 5px;
  gap: 8px;
  border-radius: 4px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 2px solid var(--secondary);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  object-fit: cover;
  background-color: #a2a2a2;
}
.progress {
  position: relative;
  height: 10px;
  width: 100%;
  border: 1px solid var(--secondary);
  border-radius: 15px;
}
.progress .color {
  position: absolute;
  background-color: var(--highlight);
  width: 0px;
  height: 8px;
  border-radius: 15px;
  animation: progress 2.5s linear;
}
.preview-content {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

/*EFFECTS*/
.browse-btn:hover {
  transform: scale(1.1);
}
::-webkit-scrollbar {
  height: 4px !important;
}

@keyframes progress {
  0% {
    display: block;
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    display: block;

    width: 100%;
  }
}
</style>
